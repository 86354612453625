switch (window.location.pathname) {
  case "/become":
    console.log("You are now logged in as an admin")
    alert("You are now logged in as an admin")
    localStorage.setItem("admin", true)
    window.location.href = "/";
    break;
  case "/exit":
    console.log("You are no longer an admin")
    alert("You are no longer an admin")
    localStorage.removeItem("admin");
    window.location.href = "/";
    break;
}